import Link from 'next/link'
import PropTypes from 'prop-types'
import { H5, H4, P1, useWindowSize } from '@moneymade/moneymade-ui'

import { ROUTES } from 'static/menusConstants'
import { removeHTMLTags } from 'utils'
import { getMonthDate } from 'modules/learn/learnUtils'
import { getCdnImg } from 'utils/getters'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import styles from './LearnCard.module.scss'

const getTypeCard = (type, slug) => {
  switch (type) {
    case 'article':
      return `/${ROUTES.learnArticle}/${slug}`
    case 'articleQA':
      return `/${ROUTES.learnArticleQA}/${slug}`
    case 'review':
    case 'platform-review':
      return `/${ROUTES.learnReview}/${slug}`
    case 'collection':
      return `/${ROUTES.collections}/${slug}`
    default:
      return ''
  }
}

const LearnCard = ({
  title,
  slug,
  image,
  background,
  logo,
  description,
  shortDescription,
  published,
  type,
  imageRounded,
  ignoreBackgroundColor,
  smallHeader,
  lazy
}) => {
  const { screenWidth } = useWindowSize()
  return (
    <Link href={getTypeCard(type, slug)} prefetch={false}>
      <a className={styles.LearnCard}>
        <div
          className={`${styles.Image} ${imageRounded && styles.Rounded}`}
          style={{ backgroundColor: !ignoreBackgroundColor ? background || '#e2232a' : '' }}
        >
          {lazy ? (
            <ImgLazy src={getCdnImg(image || logo, screenWidth <= 401 ? 400 : 358)} alt={title} />
          ) : (
            <img src={getCdnImg(image || logo, screenWidth <= 401 ? 400 : 358)} alt={title} />
          )}
        </div>

        <div className={styles.Meta}>
          <span>{getMonthDate(published)}</span>
        </div>

        {smallHeader ? (
          <H5 type="heading" weight="bold" className={styles.Title}>
            {title}
          </H5>
        ) : (
          <H4 type="heading" weight="bold" className={styles.Title}>
            {title}
          </H4>
        )}

        <P1>{description ? removeHTMLTags(description) : shortDescription}</P1>
      </a>
    </Link>
  )
}

LearnCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  background: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  published: PropTypes.string,
  type: PropTypes.string,
  imageRounded: PropTypes.bool,
  ignoreBackgroundColor: PropTypes.bool,
  smallHeader: PropTypes.bool,
  lazy: PropTypes.bool
}

LearnCard.defaultProps = {
  title: '',
  slug: '',
  background: '',
  image: '',
  logo: '',
  description: '',
  shortDescription: '',
  published: '',
  type: '',
  imageRounded: false,
  ignoreBackgroundColor: false,
  smallHeader: false,
  lazy: false
}

export default LearnCard
