import wrapper from 'redux/store'
import { redis } from 'utils/redis'
import { withRedis } from 'api/callConstructor'
import { getArticlesCall } from 'api/calls'
import NotFoundPage from 'modules/notFound/NotFoundPage/NotFoundPage'

const Custom404 = props => <NotFoundPage {...props} />

export const getStaticProps = wrapper.getStaticProps(async () => {
  const articlesPickFields =
    '&pick-fields=[*]slug&pick-fields=[*]title&pick-fields=[*]logo&pick-fields=[*]shortDescription&pick-fields=[*]published&pick-fields=[*]type'

  const articles = await withRedis(redis, getArticlesCall(articlesPickFields), `/404`)
  // if (!articles.success)
  //   throwSentryError(
  //     `Error: API URL: https://cms.moneymade.io/api/articles?_sort=created_at:DESC&publishedStatus=published${articlesPickFields}. ${articles.status} ${articles.message}`
  //   )

  return {
    props: {
      articles: articles?.success ? articles.result.slice(0, 8) : []
    },
    revalidate: 600 // 10 min
  }
})

export default Custom404
