import { useEffect } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { H1, H4, Icon, P1, Skeleton } from '@moneymade/moneymade-ui'
import Link from 'next/link'

import useTracker from 'hooks/useTracker'
import HeadTags from 'components/app/HeadTags/HeadTags'
import LearnCard from 'modules/learn/LearnCard/LearnCard'
import { ROUTES } from 'static/menusConstants'
import styles from './NotFoundPage.module.scss'

const NotFoundPage = ({ articles }) => {
  const { handleUseTracker } = useTracker()
  const { asPath, pathname } = useRouter()
  useEffect(() => {
    handleUseTracker('404', 'path', asPath)
  }, [asPath, handleUseTracker])

  return (
    <>
      <HeadTags title="404" />

      <section className={styles.NotFoundPage}>
        {/* <img className={styles.Image404} src="https://assets.moneymade.io/images/errors/404.svg" alt="404" /> */}
        <H1 className={styles.Title}>
          {pathname.includes('discover/') || pathname.includes('learn/review/') ? 'No Longer Available' : 'Oops!'}
        </H1>
        {pathname.includes('discover/') || pathname.includes('learn/review/') ? null : (
          <P1 className={styles.Subtitle}>It seems like the page you’re looking for doesn’t exist</P1>
        )}
        {articles?.length ? (
          <>
            <div className={styles.Header}>
              <H4 weight="semibold" type="heading">
                Latest Articles
              </H4>
              <Link href={`/${ROUTES.learnArticle}`}>
                <a className={styles.Link}>
                  <P1 weight="semibold">See All</P1>
                  <Icon icon="icon-arrow-right" size={16} />
                </a>
              </Link>
            </div>
            <div className={styles.Articles}>
              {articles?.length
                ? articles.map((article, index) => <LearnCard key={index} {...article} />)
                : [...Array(6)].map((_, index) => (
                    <Skeleton key={index} variant="text" height={180} spaceBottom={20} />
                  ))}
            </div>
          </>
        ) : null}
      </section>
    </>
  )
}

NotFoundPage.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object)
}

NotFoundPage.defaultProps = {
  articles: []
}
export default NotFoundPage
